<template>
  <div class="hello">
    <div id="map"></div>
  </div>
</template>
<script>
import Vue from "vue";
import Pusher from "pusher-js";
import mapboxgl from "mapbox-gl";
export default {
  name: "Goooglemap",
  props: ["data"],
  data() {
    return {
      user: JSON.parse(localStorage.getItem("alcotrackerparentuser")),
      pusher: null,
      channel: null,
      marks: null
    };
  },
  created() {
    // this.pusher = new Pusher("ca5d067792cdfce54eca", {
    //   encrypted: true,
    //   cluster: "mt1"
    // });
    // let that = this;
    // this.channel = this.pusher.subscribe(this.data[0].deviceId);
    // this.channel.bind("my-event", function(data) {
    //   var lati = parseFloat(data.lat);
    //   var long = parseFloat(data.lon);
    //   var marker = {
    //     lat: lati,
    //     lng: long
    //   };
    //   that.init(marker, data.devid);
    // });
  },
  methods: {
    init(loc, devId) {
      mapboxgl.accessToken =
        "pk.eyJ1IjoiYWxjb3Jha2UiLCJhIjoiY2tydnJub2JpMDllajJwb3h2NjdjbjFkdSJ9.6niQ3lJJ1OVEBMKj_BvFkA";
      var map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: loc,
        zoom: 12
      });
      // var popup = new mapboxgl.Popup({ offset: 25 }).setText(devId);
      // map.rotateTo(180, { duration: 1000 });
      var marker = new mapboxgl.Marker()
        .setLngLat(loc)
        // .setPopup(popup)
        .addTo(map);
      return marker;
    }
  },
  mounted() {
    Vue.axios
      .get("https://robopower.xyz/app/v2data/getlatestpingv22?cbid=" + this.data)
      .then(response => {
        if (
          response.data != null &&
          response.data.lat != "NA" &&
          response.data.lon != "NA"
        ) {
          var deal = response.data;
          var lati = parseFloat(deal.lat);
          var long = parseFloat(deal.lon);
          var marker = {
            lat: lati,
            lng: long
          };
          this.init(marker, this.data);
        }
      })
      .catch(error => {
        console.log("Error: ", error);
      });
  }
};
</script>
<style scoped>
 @import 'https://api.tiles.mapbox.com/mapbox-gl-js/v1.6.0/mapbox-gl.css';
  @import 'https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css';
#map {
  height: 100%;
  width: 100%;
}
</style>
