<script>
import Parentmap from "../subcomponents/parentMap2.vue";

export default {
  name: "busLocator",
  data() {
    return {
      vehdata: null,
      routeId: "Spl_Route",
      stopListener: true,
      deviceId: this.data
    };
  },
  props: ["vdata","sdata"],
  beforeMount() {
    this.vehdata = this.vdata.cbid;
  },
  methods: {
    onTab(tName) {
      this.$emit("tab", tName);
      this.stopListener = false;
    },
    showError(data) {
      if (data["current_location"] === null) {
        this.error =
          "There is no device in the database. So couldn't initialize the map";
      }
    }
  },
  components: {
    ParentMap: Parentmap
  }
};
</script>

<template>
  <ParentMap :data="vehdata"
  style="height: 300px !important;"></ParentMap>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
